import { useEffect } from "react";
import "./App.css";

function App() {
  const openYoutubeVideo = () => {
    const youtubeUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLScrQyf4hdPCsETwnHLoMNH9phXOu4n_mrpkUWsCnWOtKmHolw/viewform?usp=sf_link";
    window.open(youtubeUrl, "_blank");
  };
  useEffect(() => {
    const positionPlaceholders = () => {
      const placeholders = document.querySelectorAll(".image-placeholder");

      placeholders.forEach((placeholder) => {
        // Assert that placeholder is of type HTMLElement
        const element = placeholder as HTMLElement;

        const topPos = Math.floor(Math.random() * (window.innerHeight - 100)); // 100 is the height of the placeholder
        const leftPos = Math.floor(Math.random() * (window.innerWidth - 100)); // 100 is the width of the placeholder

        element.style.top = `${topPos}px`;
        element.style.left = `${leftPos}px`;
      });
    };

    positionPlaceholders();
    window.addEventListener("resize", positionPlaceholders);

    return () => window.removeEventListener("resize", positionPlaceholders);
  }, []);
  const images = ["/image1.png", "/image2.png", "/image3.png"];

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="y2k-title">Welcome to Figo</h1>
        <p className="y2k-text">
          Click the link below to join our list of testers
        </p>
        <button className="y2k-button" onClick={openYoutubeVideo}>
          😈
        </button>
      </header>
      {/* Adding image placeholders */}
      {images.map((imageSrc, index) => (
        <div
          key={index}
          className="image-placeholder"
          style={{ backgroundImage: `url(${imageSrc})` }}
        ></div>
      ))}
    </div>
  );
}

export default App;
